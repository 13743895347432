<template>
  <div class="wrapper">

    <section data-to="equipo-alumni" class="encabezado equipo-alumni row-start">

      <h2>Equipo Alumni</h2>

    </section>


    <section class="info-container">

      <h3 data-to="quienes-somos">Quiénes somos</h3>

      <div class="imagen-texto row-start equipo">

        <div class="imagen"></div>

        <div class="texto">

          <p><strong>El equipo Alumni UFV</strong> está íntegramente formado por antiguos alumnos que
            trabajan a diario para atender a otros antiguos alumnos. Nuestra misión es que los
            egresados sigan vinculados con su universidad, que sientan que UFV es y será siempre su
            casa, que pueden volver al campus siempre que quieran y que sientan que tienen a toda
            una comunidad que se preocupa por ellos.</p>

        </div>

      </div>
      <div class="contenedor-personas equipo row-start fww" data-to="responsables">

        <div class="contenedor-persona equipo show row-between">
          <div class="contenedor-imagen front" style="background: url(/img/gonzalo-barriga.jpg) center/cover no-repeat;">

            <div class="nombre column-center">
              <h5>Gonzalo Barriga</h5>
              <p>Director</p>
            </div>

          </div>
          <div class="contenedor-imagen front" style="background: url(/img/macarena-rodriguez.jpg) center/cover no-repeat;">

            <div class="nombre column-center">
              <h5>Macarena Rodríguez</h5>
              <p>Coordinadora</p>
            </div>

          </div>

          

          <div class="contenedor-imagen front" style="background: url(/img/nuria.jpg) center/cover no-repeat;">

            <div class="nombre column-center">
              <h5>Nuria Aymerich</h5>
              <p>Responsable de Comunicación</p>
            </div>

          </div>

          <div class="contenedor-imagen front" style="background: url(/img/alicia.jpg) center/cover no-repeat;">

            <div class="nombre column-center">
              <h5>Alicia Mena</h5>
              <p>Responsable del servicio de atención al antiguo alumno</p>
            </div>

          </div>

          <div class="contenedor-imagen front" style="background: url(/img/sabrina.jpg) center/cover no-repeat;">

            <div class="nombre column-center">
              <h5>Sabrina Lucas Corman</h5>
              <p>Coordinadora de ELUMNI – Antiguos Alumnos de Escuela de Liderazgo Universitario
              </p>
            </div>

          </div>
          <div class="contenedor-imagen front" style="background: url(/img/marta.jpg) center/cover no-repeat;">

            <div class="nombre column-center">
              <h5>Marta Díez</h5>
              <p>Coordinadora de Padres</p>
            </div>

          </div>

        </div>

        <template v-if="showresponsables">

          <div class="contenedor-persona row-end">
            <div class="contenedor-titulo column-center">

              <h5>Responsable alumni</h5>
              <h6>Salud</h6>

            </div>

            <div class="contenedor-imagen" style="background: url(/img/irene-heruzo.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Irene Heruzo</h5>
                <p>Medicina</p>
              </div>

            </div>
            <div class="contenedor-imagen front" style="background: url(/img/gema-mata.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Gema Mata</h5>
                <p>Enfermería</p>
              </div>

            </div>
            <div class="contenedor-imagen front" style="background: url(/img/alejandro-leal.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Alejandro Leal</h5>
                <p>Fisioterapia</p>
              </div>

            </div>

          </div>

          <div class="contenedor-persona row-end">
            <div class="contenedor-titulo column-center">

              <h5>Responsable alumni</h5>
              <h6>Experimentales</h6>

            </div>
            <div class="contenedor-imagen" style="background: url(/img/teresa-grande.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Teresa Grande</h5>
                <p>Farmacia</p>
              </div>

            </div>
            <div class="contenedor-imagen front">

              <div class="nombre column-center">
                <h5>Elvira Herrero</h5>
                <p>Biotecnología</p>
              </div>

            </div>
            <div class="contenedor-imagen front" style="background: url(/img/elena-bernabeu.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Elena Bernabéu</h5>
                <p>Psicología</p>
              </div>

            </div>
          </div>

          <div class="contenedor-persona row-end">
            <div class="contenedor-titulo column-center">

              <h5>Responsable alumni</h5>
              <h6>Comunicación</h6>

            </div>
            <div class="contenedor-imagen" style="background: url(/img/miguel-ortega.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Miguel Ortega</h5>
                <p>Publicidad</p>
              </div>

            </div>
            <div class="contenedor-imagen front" style="background: url(/img/roberto-campos.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Roberto Campos</h5>
                <p>Diseño</p>
              </div>

            </div>
          </div>

          <div class="contenedor-persona row-end">
            <div class="contenedor-titulo column-center">

              <h5>Responsable alumni</h5>
              <h6>Humanidades y educación</h6>

            </div>
            <div class="contenedor-imagen" style="background: url(/img/cristina-alberdi.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Cristina R. Alberdi</h5>
                <p>Educ. Infantil<br>Educ. Primaria</p>
              </div>

            </div>
            <div class="contenedor-imagen front" style="background: url(/img/maria-merino.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>María Merino</h5>
                <p>CAFYD</p>
              </div>

            </div>
          </div>

          <div class="contenedor-persona row-end">
            <div class="contenedor-titulo column-center">

              <h5>Responsable alumni</h5>
              <h6>Escuela Politécnica Superior</h6>

            </div>
            <div class="contenedor-imagen" style="background: url(/img/emilio-delgado.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Emilio Delgado</h5>
                <p>Arquitectura</p>
              </div>

            </div>
            <div class="contenedor-imagen front" style="background: url(/img/alvaro-garcia.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Alvaro Gª Tejedor</h5>
                <p>ING. Informática</p>
              </div>

            </div>
          </div>
          <div class="contenedor-persona row-end">
            <div class="contenedor-titulo column-center">

              <h5>Responsable alumni</h5>
              <h6>Ciencias juridicas y empresariales</h6>

            </div>
            <div class="contenedor-imagen front" style="background: url(/img/beatriz-vila.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Beatriz Vila</h5>
                <p>Excellens</p>
              </div>

            </div>
            <div class="contenedor-imagen front" style="background: url(/img/gloria-claudio.jpg) center/cover no-repeat;">

              <div class="nombre column-center">
                <h5>Gloria Claudio</h5>
                <p>Economía</p>
              </div>

            </div>

          </div>

        </template>


      </div>

      <a style="font-weight: 800;text-decoration: underline;" @click="showresponsables = !showresponsables;">
        {{(showresponsables) ? 'Cerrar' : 'Ver responsables Alumni'}}
      </a>


      <h3 data-to="donde-estamos">Dónde estamos</h3>

      <p>Desde 2018 Alumni cuenta con un espacio propio dentro del campus: el <span class="bold">ALUMNI
          CENTER.</span> Situado en el <span class="bold">Módulo 4 a pie de calle,</span> es el lugar
        donde desarrolla su actividad el equipo Alumni, donde recibimos a los antiguos alumnos que
        regresan a su Universidad y donde atendemos sus necesidades.</p>
      <p>El horario de atención a los antiguos alumnos en el <span class="bold">Alumni Center</span> es de
        lunes a viernes de 09:30 h a 14:00 y de 16:00 a 18:30 h. Puedes contactar con nosotros
        escribiendo a <a href="mailto:alumni@ufv.es">alumni@ufv.es</a> o llamando al <a href="tel:917091400">91 709 14
          00.</a></p>

      <div data-to="alumni-center" class="contenedor-alumni-center row-between fww ais">


        <div>
          <iframe width="100%" src="https://player.vimeo.com/video/369815060" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>

        <div class="mapa-alumni-center"></div>

        <div class="espacio-nuevo3"></div>

        <div class="espacio-nuevo4"></div>

      </div>
    </section>

    <section class="encabezado vida-alumni row-start" data-to="vida-alumni">
      <h2>Vida alumni</h2>
    </section>

    <section class="info-container">

      <h3 data-to="entrevistas">Entrevistas</h3>

      <h4>Últimas entrevistas</h4>

      <entrevistas></entrevistas>

      <eventos></eventos>

      <h3 data-to="noticias">Noticias</h3>

      <noticias></noticias>

    </section>

    <section class="encabezado benefits row-start" data-to="benefits">

      <h2>Benefits</h2>

    </section>

    <section class="info-container">

      <h3>Benefits</h3>

      <p>¿Sabes que puedes ir al cine, Kinepolis y Cinesa, por menos de 7 €, cualquier día de la semana, y
        con todos los acompañantes que quieras?. Si estás dado de alta como antiguo alumno tenemos
        preparadas cientos de ofertas exclusivas en tecnología, moda, alimentación, viajes, ocio, etc.
        ¡ENTRA Y DESCÚBRELAS!</p>

      <div class="contenedor-alumni-center row-between fww">

        <div>
          <iframe src="https://player.vimeo.com/video/268373801" width="500" height="500" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>


        </div>

        <div>
          <iframe src="https://player.vimeo.com/video/254871506" width="500" height="500" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>

      </div>

      <a :href="urlbenefits" target="_blank" v-if="urlbenefits" class="js-benefits boton-azul alone">Ir a Benefits</a>
      <router-link v-else :to="`/login?callback=/home#benefits`" class="js-benefits boton-azul alone">Inscribirse</router-link>

      <a href="" class="js-fake-benefits"></a>


    </section>

    <section class="encabezado empleo row-start" data-to="empleo">

      <h2>Empleo</h2>

    </section>


    <section class="info-container">

      <h3>Empleo</h3>

      <p>La UFV, a través del departamento de Carreras Profesionales, ofrece a sus antiguos alumnos un servicio gratuito de orientación profesional para la empleabilidad, bolsa de empleo joven, eventos y encuentros de networking con profesionales y formaciones transversales para la empleabilidad</p>

      <a href="https://carrerasprofesionales-ufv.es/" target="_blank" class="js-empleo boton-azul alone">Ir a Carreras Profesionales UFV</a>
      <div class="imagen-texto row-between centro-emprendimiento">

        <div class="imagen bg-empleo"></div>

        <div class="texto">

          <p><strong>Alumni Emplea a Alumni.</strong> Si hay alguna vacante en tu empresa o eres empresario y necesitas cubrir un puesto de trabajo entre la comunidad de antiguos alumnos, puedes encontrar a la persona que estás buscando. Haznos llegar tu oferta a <a href="mailto:c.profesionales@ufv.es">c.profesionales@ufv.es</a> (<a href="http://carrerasprofesionales-ufv.es" target="_blank" rel="noopener noreferrer">carrerasprofesionales-ufv.es</a> ) y nosotros te conectamos con los posibles candidatos.</p>
        </div>

      </div>



    </section>

    <section class="encabezado emprendedores row-start" data-to="emprendedores">

      <h2>Emprendedores</h2>

    </section>


    <section class="info-container">


      <h3>Emprendedores</h3>
      <p>Entre los egresados hay cientos de emprendedores. Universitarios que han decidido arriesgar y
        lograr cumplir sus sueños profesionales.</p>
      <p>Desde Alumni nos encanta conocer y apoyar todas las iniciativas emprendedoras. Si tienes tu
        propio negocio, cuéntanoslo y te apoyaremos en todo lo que podamos.</p>


      <emprendedores></emprendedores>


      <div class="imagen-texto row-between centro-emprendimiento">

        <div class="imagen bg-centro-emprendimiento"></div>

        <div class="texto">

          <p><strong>Centro de emprendimiento</strong>. Si tienes una idea, quieres emprender y no
            sabes por dónde empezar o tienes dudas sobre la creación de tu empresa, el Centro de
            Emprendimiento te asesorará y orientará para que puedas hacer realidad tu "Proyecto o
            Startup".</p>
          <p>Puedes realizar cualquier consulta escribiendo a la responsable: Begoña Viejo
            begona.viejo@ufv.es , o llamar por telf. al 91 709 14 00-Ext 2191.</p>

          <a href=" https://www.ufv.es/red-de-inversores-centro-de-emprendimiento/" target="_blank" class="boton-azul">Ir a Centro de emprendimiento</a>

        </div>

      </div>


    </section>


    <section class="encabezado orlas row-start" data-to="emprendedores" id="orla">

      <h2>ORLA</h2>

    </section>



  </div>
</template>

<script>

  import noticias from '@/components/noticias-home'
  import entrevistas from '@/components/entrevistas-home'
  import emprendedores from '@/components/emprendedores-home'
  import eventos from '@/components/eventos-home'

  import { mapGetters } from 'vuex';

  export default {
    name: 'Home',
    mounted() {
      this.checkscroll();
    },
    data: () => ({
      showresponsables: false
    }),
    methods: {
      checkscroll() {
        if (this.$route.hash) {
          setTimeout(() => {
            let offsetTop = document.querySelector(`*[data-to=${this.$route.hash.split("#")[1]}]`).offsetTop;
            scroll({
              top: offsetTop,
              behavior: "smooth"
            });
          }, 1000);
        }
      }
    },
    components: {
      'noticias': noticias,
      'entrevistas': entrevistas,
      'emprendedores': emprendedores,
      'eventos': eventos
    },
    computed: {
      ...mapGetters({
        urlbenefits: 'geturlBenefits'
      })
    },
    watch: {
      '$route'() {
        this.checkscroll();
      }
    }
  }
</script>